// PdfTextConfigs.js

const DEFAULT_X = 120;
const NEXT_X = 652;
const DEFAULT_Y = 125;
const DEFAULT_FONT_SIZE = 17;
const DEFAULT_MAX_WIDTH = 30;
const DEFAULT_LINE_HEIGHT = 20;

const PdfTextConfigs = [
    {
        inputIndex: 0, //ふりがな氏名
        x: DEFAULT_X,
        y: DEFAULT_Y,
        fontSize: 14,
        maxWidth: DEFAULT_MAX_WIDTH,
        lineHeight: DEFAULT_LINE_HEIGHT,
    },
    {
        inputIndex: 1, //漢字氏名
        x: DEFAULT_X,
        y: 180,
        fontSize: 36,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 2, //生年月日 と年齢
        x: DEFAULT_X - 20,
        y: 223,
        fontSize: DEFAULT_FONT_SIZE,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 3, //性別
        x: DEFAULT_X + 350,
        y: 223,
        fontSize: DEFAULT_FONT_SIZE,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 4, //郵便番号（現住所）
        x: DEFAULT_X + 10,
        y: 257,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 5, //現住所
        x: DEFAULT_X,
        y: 272,
        fontSize: 14,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 6, //ふりがな現住所
        x: DEFAULT_X,
        y: 243,
        fontSize: 11,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 7, //電話番号１
        x: DEFAULT_X +340,
        y: 270,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 8, //電話番号２
        x: DEFAULT_X +340,
        y: 320,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 9, //郵便番号（連絡先）
        x: DEFAULT_X + 10,
        y: 322,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 10, //連絡先住所
        x: DEFAULT_X,
        y: 335,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 11, //ふりがな連絡先住所
        x: DEFAULT_X,
        y: 307,
        fontSize: 11,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 12, //学歴・職歴１
        x: 120,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 13, //学歴・職歴２
        x: 120,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 14, //学歴・職歴３
        x: 120,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 15, //学歴・職歴4
        x: 120,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 16, //学歴・職歴5
        x: NEXT_X,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 17, //学歴・職歴6
        x: 120,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 18, //学歴・職歴7
        x: 120,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 19, //学歴・職歴8
        x: 120,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 20, //学歴・職歴9
        x: 120,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 21, //学歴・職歴10
        x: 120,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 22, //免許・資格１
        x: 120,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 23, //免許・資格２
        x: 120,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 24, //免許・資格３
        x: 120,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 25, //免許・資格４
        x: 120,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 26, //免許・資格５
        x: 120,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 27, //免許・資格６
        x: 120,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
    {
        inputIndex: 28, //本人希望記入欄
        x: NEXT_X,
        y: 695,
        fontSize: 14,
        maxWidth: 120,
        lineHeight: 16,
    },
    {
        inputIndex: 29, //PDF送付用メールアドレス
        x: 120,
        y: 300,
        fontSize: 12,
        maxWidth: 120,
        lineHeight: 14,
    },
];

export default PdfTextConfigs;