// pdfFormLabels.js
const pdfFormLabels = [
    {
      label:'ふりがな氏名',
      name:'kana-name',
      type:'text'
    },
    {
      label: '漢字氏名',
      name: 'name',
      type: 'text'
    },
    {
      label: '生年月日',
      name: 'birth',
      type: 'text'
    },
    {
      label: '性別',
      name: 'gender', // sexualをgenderに変更
      type: 'select', // typeをselectに変更
      options: ['男性', '女性', 'その他']
    },
    {
      label: '郵便番号（現住所）',
      name: 'post-number',
      type: 'number' //これであっているのか？
    },
    {
      label: '現住所',
      name: 'address',
      type: 'text'
    },
    {
      label: 'ふりがな現住所',
      name: 'kana-address',
      type: 'text'
    },
    {
      label: '電話番号①',
      name: 'phone1',
      type: 'text'
    },
    {
      label: '電話番号②',
      name: 'phone2',
      type: 'text'
    },
    {
      label: '郵便番号（連絡先）',
      name: 'contact-post-number',
      type: 'number'
    },
    {
      label: '連絡先住所',
      name: 'contact-address',
      type: 'text'
    },
    {
      label: 'ふりがな連絡先住所',
      name: 'kana-contact-address',
      type: 'text'
    },
    {
      label: '学歴１', //index13
      name: 'education1',
      type: 'text'
    },
    {
      label: '学歴２',
      name: 'educationk2',
      type: 'text'
    },
    {
      label: '学歴３',
      name: 'education3',
      type: 'text'
    },
    {
      label: '学歴４',
      name: 'education4',
      type: 'text'
    },
    {
      label: '学歴５',
      name: 'education5',
      type: 'text'
    },
    {
      label: '職歴１',
      name: 'education-work6',
      type: 'text'
    },
    {
      label: '職歴２',
      name: 'education-work7',
      type: 'text'
    },
    {
      label: '職歴３',
      name: 'education-work8',
      type: 'text'
    },
    {
      label: '職歴４',
      name: 'education-work9',
      type: 'text'
    },
    {
      label: '職歴５',
      name: 'education-work10',
      type: 'text'
    },
    {
      label: '免許・資格１',
      name: 'license-qualification1',
      type: 'text'
    },
    {
      label: '免許・資格２',
      name: 'license-qualification2',
      type: 'text'
    },
    {
      label: '免許・資格３',
      name: 'license-qualification3',
      type: 'text'
    },
    {
      label: '免許・資格４',
      name: 'license-qualification4',
      type: 'text'
    },
    {
      label: '免許・資格５',
      name: 'license-qualification5',
      type: 'text'
    },
    {
      label: '免許・資格６',
      name: 'license-qualification6',
      type: 'text'
    },
    {
      label: '本人希望記入欄',
      name: 'applicant-wish',
      type: 'text'
    },
    {
      label: 'PDF送付用メールアドレス',
      name: 'pdf-email',
      type: 'text'
    }

];

export default pdfFormLabels;