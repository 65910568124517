import React, { useState, useEffect, useRef } from "react";
import InputField from './InputField.js';
import HistoryField0 from './HistoryField0';
import HistoryField1 from './HistoryField1';
import HistoryField2 from './HistoryField2';
import HistoryField3 from './HistoryField3';
import PdfTextConfigs from "./PdfTextConfigs";
import pdfFormLabels from './pdfFormLabels.js';
import { PDFDocument } from "pdf-lib";
import fontkit from '@pdf-lib/fontkit'
import axios from 'axios';
import "./App.css";

function App() {

  //プロンプト用入力フォームのラベル
  const labels = [
    "　 　あなたの志望動機",
    "　　 　　あなたの特技",
    "あなたの性質・長所",
    "学生時代 力を入れたこと①必須",
    "学生時代 力を入れたこと②（任意）",
    "学生時代 力を入れたこと③（任意）",
    "志望企業のサイトURL",
    "募集要項のURL",
    "応募職種",
  ];
  //プロンプト用入力フォームの例示
  const placeholders = [
    "例文を表示",
    "例文を表示",
    "ポジティブな性格、地道な作業が得意、責任感が強い、向上心があるなど、",
    "例：新聞記者の仕事を通じて、忍耐強さを身につけました。また、現場の責任者としてチームをまとめる能力を身につけました。",
    "例：学生時代にウインドサーフィンをやっていました。地道に練習を繰り返す大切さを学びました。",
    "例：",
    "",
    "",
    "",
  ];

  //メールアドレス取得用仮想DOMのref
  const emailInputRef = useRef(null);

  //// OpenAI API部分
  //  OpenAI APIへのリクエストに必要な項目
  const [formValues, setFormValues] = useState(
      Array(9)
          .fill({})
          .map(() => ({ prompt: "" }))
  );

  // OpenAI APIからのレスポンスを格納する定数
  const [response, setResponse] = useState("");

  // ユーザーのグローバルIPを格納するステート
  const [globalIP, setGlobalIP] = useState("");

  //コンポーネントがマウントされたときに、ユーザーのグローバルIPを取得してglobalIPステートに格納する
  useEffect(() => {
    async function fetchGlobalIPAddress() {
      const ip = await getGlobalIPAddress();
      setGlobalIP(ip);
    }

    fetchGlobalIPAddress();
  }, []);

  ////PDF印字用部分
  // PDF印字用の入力フォーム
  const [pdfFormValues, setPdfFormValues] = useState(
      Array(30)
          .fill({})
          .map(() => ({ text: "" }))
  );

  //PDF出力時にバックエンドからDBにデータを送る定数
  const [datasetB, setDatasetB] = useState({ datasetA: [], additionalData: [], apiInfo: "" });


  //await用のエラーハンドリング
  const handleAsync = async (promise) => {
    try {
      const result = await promise;
      return [result, null];
    } catch (error) {
      console.error(error);
      return [null, error];
    }
  };


  //handleSubmit内で使う処理をモジュール化 バックエンドへpromptをPOST
  const fetchOpenAI = async (prompts, body) => {
    const [result, resultError] = await handleAsync(fetch("http://localhost:3001/api/openai", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }));

    if (resultError) {
      // エラーハンドリング
      return [null, resultError];
    }

    // フロントエンドからの応答を受け取る
    const data = await result.text();
    return [data, null];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('server.jsへ送信');

    // fetchOpenAI 関数を使って API からデータを取得
    const prompts = formValues.map((item) => item.prompt);

    // リクエストボディに data プロパティを追加
    const requestBody = {
      data: {
        prompts: prompts,
        globalIPAddress: globalIP,
      },
    };

    const [data, dataError] = await fetchOpenAI(prompts, requestBody);

    if (dataError) {
      // エラーハンドリング
      return;
    }

    setResponse(data);
    // グローバルIPをデータセットに含める
    setDatasetB({ ...datasetB, globalIP });
  };

  //ユーザーのグローバルIPを取得する関数
  async function getGlobalIPAddress() {
    const response = await fetch('https://api.ipify.org?format=json');
    const ipAddressData = await response.json();
    return ipAddressData.ip;
  }

  //プロンプト生成handleChangeとPDF生成handlePdfChangeの両方で使う部分をモジュール化
  const handleInputChange = (index, value, formState, setFormState, fieldName) => {
    const newFormValues = [...formState];
    newFormValues[index][fieldName] = value;
    setFormState(newFormValues);
  };

  //プロンプト情報入力フォームの内容をプロンプトにする関数
  const handleChange = (index, value, fieldName) => {
    handleInputChange(index, value, formValues, setFormValues, 'prompt', fieldName);
  };

  const handlePdfChange = (index, value, fieldName) => {
    handleInputChange(index, value, pdfFormValues, setPdfFormValues, fieldName);
  };

  //PDF印字内容の折返しを設定する関数
  async function createWrappedText(doc, text, maxWidth, fontSize, font) {
    const lines = [];
    const chars = [...text]; // 文字ごとに分割
    let currentLine = '';

    for (const char of chars) {
      const testLine = currentLine + char;
      const testLineWidth = font.widthOfTextAtSize(testLine, fontSize);

      // テスト行が最大幅を超える場合、折り返しを実行
      if (testLineWidth > maxWidth * fontSize) {
        lines.push(currentLine);
        currentLine = char;
      } else {
        currentLine = testLine;
      }
    }
    lines.push(currentLine);

    return lines;
  }

  //PDFへの印字設定
  const writeTextToPdf = async (pdfDoc, text, x, y, maxWidth, lineHeight, fontSize, font) => {
    const wrappedText = await createWrappedText(pdfDoc, text, maxWidth, fontSize, font);
    const page = pdfDoc.getPages()[0];

    wrappedText.forEach((line, i) => {
      page.drawText(line, {
        x: x,
        y: page.getHeight() - y - i * lineHeight,
        size: fontSize,
        font: font,
      });
    });
  };

  //...
// 各データをバックエンドに送信して、Prismaに格納する関数
  const sendDatasetBToBackend = async () => {
    const datasetA = formValues.map(item => item.prompt);
    const additionalData = pdfFormValues.map(item => item.text);
    const globalIP = datasetB.globalIP;

    const datasetBData = {
      datasetA: datasetA,
      additionalData: additionalData,
      apiInfo: response,
      globalIP: globalIP // データセットにグローバルIPを含める
    };

    try {
      await axios.post('http://localhost:3001/api/datasetB', { data: datasetBData });

      console.log('All data saved successfully');
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  //あらかじめ用意したPDFの書式に、OpenAIの解答を書き込む
  const modifyExistingPdf = async (returnBlob) => {
    if (returnBlob === undefined) {
      returnBlob = false;
    }
    console.log("modifyExistingPdf called with returnBlob:", returnBlob);
    const [existingPdfBytes, existingPdfError] = await handleAsync(fetch("/test.pdf").then((res) => res.arrayBuffer()));
    if (existingPdfError) {
      // エラーハンドリング
      return;
    }
    console.log('PDF loaded', existingPdfBytes);
    const pdfDoc = await PDFDocument.load(existingPdfBytes, {
      ignoreEncryption: true,
      fontkit: fontkit,
    });
    pdfDoc.registerFontkit(fontkit);
    const [fontBytes, fontBytesError] = await handleAsync(fetch("/ipam.ttf").then((res) => res.arrayBuffer()));
    if (fontBytesError) {
      // エラーハンドリング
      return;
    }
    const customFont = await pdfDoc.embedFont(fontBytes);
//    const firstPage = pdfDoc.getPages()[0];

    //折り返しの最大幅、折り返しに関わるのでフォントサイズも先に設定している。
    const maxWidth = 37; //文字数
    const fontsize = 13;
    const lineHeight = 16;

    //OpenAIからの応答 responseを印字の内容として指定する PdfTextConfigs.jsを参照する
    for (let i = 0; i < PdfTextConfigs.length; i++) {
      const config = PdfTextConfigs[i];
      const inputText = pdfFormValues[config.inputIndex].text; // 対応する入力テキストを取得
      await writeTextToPdf(
          pdfDoc,
          inputText,
          config.x,
          config.y,
          config.maxWidth,
          config.lineHeight,
          config.fontSize,
          customFont
      );
    }


    // OpenAI APIから取得した内容をPDFに書き込む
    const responsePosition = { x: 650, y: 500 }; // 応答テキストの書き込み開始位置

    await writeTextToPdf(
        pdfDoc,
        response,
        responsePosition.x,
        responsePosition.y,
        maxWidth,
        lineHeight,
        fontsize,
        customFont
    );

    //印字後のPDFをsaveしてBlobで掴み、downloadする
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], {type: "application/pdf"});
    if (returnBlob) {
      console.log("Returning blob");
      // Blobを返す場合 (メール送信用)
      return blob;
    } else {
      // Blobをダウンロードする場合
      console.log("Downloading blob");
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "appeal_point.pdf";
      link.click();
      // PDF出力処理終了後にデータセットBをバックエンドに送信
      sendDatasetBToBackend();
    }
  };

  // PDFダウンロード処理
  const handleDownloadPdf = async () => {
    await modifyExistingPdf();
  };

  // PDFメール送信処理
  const handleSendEmail = async () => {
    console.log("handleSendEmail called and check 2files");
    console.log('emailInputRef.current', emailInputRef.current);
    console.log("Calling modifyExistingPdf with returnBlob:", true);
    const pdfBlob = await modifyExistingPdf(true);
    console.log('pdfBlob:', pdfBlob);
    console.log('pdfBlob is an instance of Blob:', pdfBlob instanceof Blob);
    console.log("FileReader will be defined", true);
    const reader = new FileReader();
    console.log("FileReader was defined", true);

    reader.onload = async function (event) {
      const base64Pdf = event.target.result;
//      console.log("Base64 data:", base64Pdf);
      const emailAddress = emailInputRef.current.value;


      try {
        console.log("send email Address", emailAddress);
        const response = await fetch('http://localhost:3001/api/email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
            body: JSON.stringify({ base64pdf: base64Pdf, emailAddress: emailAddress })
//          body: JSON.stringify({ base64Pdf, emailAddress }),
//          body: JSON.stringify({ emailAddress }),
        });

        if (response.ok) {
          alert('Email sent successfully');
        } else {
          console.error('Error sending email');
          alert('Failed to send email');
        }
      } catch (error) {
        console.error(`Error sending email: ${error}`);
        alert('Failed to send email');
      }
    };

    if (pdfBlob instanceof Blob) {
      console.log("pdfBlob is a Blob instance");
    } else {
      console.log("pdfBlob is not a Blob instance");
    }
    reader.readAsDataURL(pdfBlob);
  }

  return (
      <div className="App">
        <h1>あなたの自己アピール欄つくります</h1>
        <form onSubmit={handleSubmit}>
          {formValues.map((item, index) => {
            return (
                <div key={index}>
                  <label htmlFor={`prompt${index}`}>{labels[index]}:</label>
                  <input
                      type="text"
                      id={`prompt${index}`}
                      value={item.prompt}
                      onChange={(e) => handleChange(index, e.target.value)}
                      placeholder={placeholders[index]}
                  />
                </div>
            );
          })}
          <button type="Submit">文章生成</button>
        </form>
        <div className="response">
          <label htmlFor="response">生成された文章:</label>
          <textarea
              id="response"
              value={response}
              onChange={(e) => setResponse(e.target.value)}
              rows={10}
              cols={58}
          />
        </div>
        <h2>履歴書まるごと作成 入力フォームはこちら</h2>
        <form>
          {pdfFormValues.map((item, index) => {
            const currentField = pdfFormLabels[index];

            // 通常の InputField
            if (index === 2) {
              return (
                  <HistoryField0
                      key={index}
                      label={currentField.label}
                      id={`pdfInput${index}`}
                      name={currentField.name}
                      value={item.text}
                      index={index}
                      onChange={(i, value, fieldName) => handlePdfChange(i, value, fieldName)}
                  />
              );
            }
            else if (index < 12 || index === 28 || index === 29) {
              return (
                  <InputField
                      key={index}
                      label={currentField.label}
                      id={`pdfInput${index}`}
                      name={currentField.name}
                      value={item.text}
                      onChange={(index, value, field) => handlePdfChange(index, value, field)}
                      ref={index === 29 ? emailInputRef : undefined}
                  />
              );
            }
            // 13 から 17 までのインデックスでは HistoryField1 を使用
            else if (index >= 12 && index <= 17) {
              return (
                  <HistoryField1
                      key={index}
                      label={currentField.label}
                      id={`pdfInput${index}`}
                      name={currentField.name}
                      value={item.text}
                      index={index}
                      onChange={(i, value, fieldName) => handlePdfChange(i, value, fieldName)}
                  />
              );
            }
            // 18 から 21 までのインデックスでは HistoryField2 を使用
            else if (index >= 18 && index <= 21) {
              return (
                  <HistoryField2
                      key={index}
                      label={currentField.label}
                      id={`pdfInput${index}`}
                      name={currentField.name}
                      value={item.text}
                      index={index}
                      onChange={(i, value, fieldName) => handlePdfChange(i, value, fieldName)}
                  />
              );
            }
            // 22 から 28 までのインデックスでは HistoryField3 を使用
            else if (index >= 22 && index <= 28) {
              return (
                  <HistoryField3
                      key={index}
                      label={currentField.label}
                      id={`pdfInput${index}`}
                      name={currentField.name}
                      value={item.text}
                      index={index} // 追加: index を渡す
                      onChange={handlePdfChange} // 修正: アロー関数を削除
                  />
              );
            } else {
            return null; // すべての条件分岐に対して値を返す
          }
          })}
        </form>
        <button onClick={handleDownloadPdf}>PDFで履歴書を出力</button>
        <br />
        <button onClick={handleSendEmail}>PDFをメールアドレスへ送信</button>
      </div>
  );
}

export default App;