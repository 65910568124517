import React from 'react';

const HistoryField0 = React.forwardRef(({ label, id, name, value, onChange, index }, ref) => {
    const eras = ["昭和", "平成", "令和"];
    const years = Array.from({ length: 70 }, (_, i) => i + 1956); // 1956年〜2025年を生成 (範囲を必要に応じて変更してください)
    const months = Array.from({ length: 12 }, (_, i) => i + 1); // 1〜12の月を生成
    const days = Array.from({ length: 31 }, (_, i) => i + 1); // 1〜31の日を生成

    return (
        <div>
            <label htmlFor={`${id}_era`}>{label} - 年号:</label>
            <select
                id={`${id}_era`}
                name={`${name}_era`}
                value={value.era}
                onChange={(e) => onChange(index, e.target.value, 'era')} // 修正済み
                ref={ref}
            >
                {eras.map((era) => (
                    <option key={era} value={era}>
                        {era}
                    </option>
                ))}
            </select>
            <label htmlFor={`${id}_year`}>年:</label>
            <select
                id={`${id}_year`}
                name={`${name}_year`}
                value={value.year}
                onChange={(e) => onChange(index, e.target.value, 'year')}
                ref={ref}
            >
                {years.map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
            <label htmlFor={`${id}_month`}>月:</label>
            <select
                id={`${id}_month`}
                name={`${name}_month`}
                value={value.month}
                onChange={(e) => onChange(index, e.target.value, 'month')}
                ref={ref}
            >
                {months.map((month) => (
                    <option key={month} value={month}>
                        {month}
                    </option>
                ))}
            </select>
            <label htmlFor={`${id}_day`}>日:</label>
            <select
                id={`${id}_day`}
                name={`${name}_day`}
                value={value.day}
                onChange={(e) => onChange(index, e.target.value, 'day')}
                ref={ref}
            >
                {days.map((day) => (
                    <option key={day} value={day}>
                        {day}
                    </option>
                ))}
            </select>
        </div>
    );
});

export default HistoryField0;