import React from 'react';

const InputField = React.forwardRef(({ label, id, name, value, onChange }, ref) => {
    return (
        <div>
            <label htmlFor={id}>{label}:</label>
            <input
                type="text"
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                ref={ref}
            />
        </div>
    );
});

export default InputField;