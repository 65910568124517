import React from 'react';

const HistoryField2 = React.forwardRef(({ label, id, name, value, onChange, index }, ref) => {
    const eras = ["昭和", "平成", "令和"];
    const months = Array.from({ length: 12 }, (_, i) => i + 1); // 1〜12の月を生成
    const statuses = ["入社", "退社"];

    return (
        <div>
            <label htmlFor={`${id}_era`}>{label} - 年号:</label>
            <select
                id={`${id}_era`}
                name={`${name}_era`}
                value={value.era}
                onChange={(e) => onChange(index, e.target.value, 'era')} // 修正済み
                ref={ref}
            >
                {eras.map((era) => (
                    <option key={era} value={era}>
                        {era}
                    </option>
                ))}
            </select>
            <label htmlFor={`${id}_year`}>年数:</label>
            <input
                type="text"
                id={`${id}_year`}
                name={`${name}_year`}
                value={value.year}
                onChange={(e) => onChange(index, e.target.value, 'year')}
                ref={ref}
            />
            <label htmlFor={`${id}_month`}>月:</label>
            <select
                id={`${id}_month`}
                name={`${name}_month`}
                value={value.month}
                onChange={(e) => onChange(index, e.target.value, 'month')}
                ref={ref}
            >
                {months.map((month) => (
                    <option key={month} value={month}>
                        {month}
                    </option>
                ))}
            </select>
            <label htmlFor={`${id}_org`}>組織名:</label>
            <input
                type="text"
                id={`${id}_org`}
                name={`${name}_org`}
                value={value.org}
                onChange={(e) => onChange(index, e.target.value, 'org')}
                ref={ref}
            />
            <label htmlFor={`${id}_status`}>ステータス:</label>
            <select
                id={`${id}_status`}
                name={`${name}_status`}
                value={value.status}
                onChange={(e) => onChange(index, e.target.value, 'status')}
                ref={ref}
            >
                {statuses.map((status) => (
                    <option key={status} value={status}>
                        {status}
                    </option>
                ))}
            </select>
        </div>
    );
});

export default HistoryField2;